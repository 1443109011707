@import "~leaflet/dist/leaflet.css";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.css";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.css";

.backdrop-on-mobile {}

.border-y>div:not(:last-child) {
  border-bottom: 1px solid #dee2e6 !important;
}

.sticky-top {
  z-index: 999;
}

.scroll-spy-btn {
  letter-spacing: 0 !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding: 0.5rem 0.3rem !important;
  margin-right: 0.1rem !important;
  font-size: 15px !important;
  color: #555555 !important;
  border-width: 1px !important;
  border-color: #999 !important;
  background-color: white !important;
}

.scroll-spy-btn.scroll-spy-btn-grey {
  background-color: #dddddd !important;
}

.scroll-spy-btn:hover {
  color: #fff !important;
  background-color: #dddddd !important;
  border-color: #888 !important;
}

@media (max-width: 420px) {
  .modal-dialog {
    margin: 0;
  }

  .scroll-spy-btn {
    padding: 0.4rem 0.2rem !important;
    margin-right: 0.08rem !important;
    font-size: 13px !important;
    border-width: 1px !important;
    /* border-color: #999; */
  }

  .scroll-spy-btn.scroll-spy-btn-grey {
    background-color: #dddddd !important;
  }
}

.scontainer {
  max-width: 100%;
}

.ta-results.list-group {
  max-width: 400px;
  overflow: auto;
  max-height: 75vh;
  border: 1px solid #ccc;
}

.typeahead-wrap .list-group-item {
  width: 100%;
  text-align: left;
  position: relative;
  display: block;
  padding: 5px 10px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.typeahead-wrap .list-group-item {
  width: 100%;
  text-align: left;
}

.typeahead-wrap .list-group-item.active {
  background-color: #ccc;
}

.typeahead-wrap .list-group-item .item-name {
  font-weight: bold;
}

/* CUSTOM CHECKBOXES  */
.custom-boxes {
  margin: auto;
  padding: 10px;
}

/*Checkboxes styles*/
.custom-boxes input[type="checkbox"] {
  display: none;
}

.custom-boxes input[type="checkbox"]+label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.custom-boxes input[type="checkbox"]+label:last-child {
  margin-bottom: 0;
}

.custom-boxes input[type="checkbox"]+label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #11698f;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

.custom-boxes input[type="checkbox"]:checked+label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-check-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0.3em;
}

.custom-check-group input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.custom-check-group label {
  position: relative;
  margin-right: 1em;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
}

.custom-check-group label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid #a9a9a9;
  border-radius: 0.25em;
  z-index: -1;
}

/* Checked */
.custom-check-group input[type="checkbox"]:checked+label {
  padding-left: 1em;
  color: #8e44ad;
}

.custom-check-group input[type="checkbox"]:checked+label:before {
  top: 0;
  width: 100%;
  height: 2em;
  background: #a9a9a9;
}

/* Transition */
.custom-check-group label,
.custom-check-group label::before {
  -webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

.searchBoxTop {
  width: 100%;
}

.ph-item {
  direction: ltr;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 15px 15px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}

.ph-item,
.ph-item *,
.ph-item :after,
.ph-item :before {
  box-sizing: border-box;
}

.ph-item:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 0.8s linear infinite;
  animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsla(0, 0%, 100%, 0.35) 50%,
      hsla(0, 0%, 100%, 0) 54%) 50% 50%;
}

.ph-item>* {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-right: 15px;
  padding-left: 15px;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}

.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #ced4da;
}

.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}

.ph-row .empty {
  background-color: hsla(0, 0%, 100%, 0);
}

.ph-col-2 {
  flex: 0 0 16.66667%;
}

.ph-col-4 {
  flex: 0 0 33.33333%;
}

.ph-col-6 {
  flex: 0 0 50%;
}

.ph-col-8 {
  flex: 0 0 66.66667%;
}

.ph-col-10 {
  flex: 0 0 83.33333%;
}

.ph-col-12 {
  flex: 0 0 100%;
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.ph-avatar:before {
  content: " ";
  display: block;
  padding-top: 100%;
}

.ph-picture {
  width: 100%;
  height: 120px;
  background-color: #ced4da;
  margin-bottom: 15px;
}

@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  to {
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  to {
    transform: translate3d(30%, 0, 0);
  }
}

.e-btn {
  display: inline-block;
  font-weight: 400;
  color: #4e66f8;
  border-color: #4e66f8;
  cursor: pointer;
  background-color: white;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0.4rem;
  padding: 3px 12px !important;
  height: auto !important;
  opacity: 0.8;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.e-btn:hover {
  opacity: 1;
}

.e-cancel {
  color: #ffffff;
  background-color: #e3165b;
  border: 0;
}

.e-apply {
  color: #ffffff;
  background-color: #4e66f8;
  border: 0;
}

.e-start-btn,
.e-end-btn {
  padding: 2px !important;
  font-size: 12px !important;
}

.cal-input-icon {
  z-index: 2;
  position: absolute;
  right: 9px;
  top: 6px;
  font-size: 26px;
  cursor: pointer;
}